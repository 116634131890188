import React, {useEffect, useState} from 'react';
import {getLogo} from '../contentful';
import {Link} from 'react-router-dom';
import styled from '@emotion/styled';

const Logo = styled.img`
	@media (max-width: 600px) {
		position: absolute;
		height: 3rem;
		width: 3rem;
		top: 1rem;
		left: 1rem;
	}
`;

const LinksContainer = styled.div`
	padding-right: 4rem;
	@media (max-width: 600px) {
		padding-right: 0;
	}
`;

export default function Header() {
	const [logo, setLogo] = useState<string>();

	useEffect(() => {
		(async () => {
			const data = await getLogo();
			setLogo(data);
		})();
	}, []);

	return (
		<header className='px-10 py-2 sticky top-0 opacity-1 z-[100] font-light' style={{backgroundColor: '#B1E0E4'}}>
			<div className='flex items-center justify-between'>
				<Link to='/'>
					<Logo src={logo} alt='logo' height={80} width={80}/>
				</Link>
				<LinksContainer className='text-deep-purple ml-10 flex gap-4 justify-end'>
					<Link to='/' className='ml-4 hover:text-unique-coral'>
						Home
					</Link>
					<Link to='/consultants' className='ml-4 hover:text-unique-coral'>
						Consultants
					</Link>
					<Link to='/about-us' className='ml-4 hover:text-unique-coral'>
						About us
					</Link>
				</LinksContainer>
			</div>
		</header>
	);
}
