import React from 'react';

type ButtonProps = {
	className?: string;
	style?: React.CSSProperties;
	onClick: React.MouseEventHandler<HTMLButtonElement>;
	text: string;
};

export default function Button({
	className,
	style,
	onClick,
	text,
}: ButtonProps) {
	const privClass = `relative bg-transparent border-2 border-terracota rounded-full text-terracota p-4 px-12 hover:opacity-70 ${className ?? ''}`;
	const privStyle = style ?? {};

	return <button className={privClass} style={privStyle} onClick={onClick}>
		{text}
	</button>;
}
