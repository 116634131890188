import React, {useEffect, useRef, useState} from 'react';
import './Carousel.css';
import {type CarouselCardType} from '../types';
import {useNavigate} from 'react-router-dom';

const Carousel = ({cards, carouselTitle = ''}: {cards: CarouselCardType[]; carouselTitle?: string}) => {
	const navigate = useNavigate();
	const [currentCard, setCurrentCard] = useState(0);
	const activeCardRef = useRef<HTMLDivElement>(null);

	const goToNextCard = () => {
		setCurrentCard(prevCard => (prevCard + 1) % cards.length);
	};

	const goToPrevCard = () => {
		setCurrentCard(
			prevCard => (prevCard - 1 + cards.length) % cards.length,
		);
	};

	const goToCard = (index: number) => {
		setCurrentCard(index);
	};

	return (
		<div className='carousel'>
			<div className='carousel-title'>{carouselTitle}</div>
			<div className='cards-wrapper'>
				{cards?.length && <div className='cards' style={{transform: `translateX(-${(currentCard * (activeCardRef?.current ? activeCardRef.current.clientWidth : 0)) + (currentCard * 40)}px)`}}>
					{cards.map((card: CarouselCardType, index) => (
						<div
							key={card.id}
							className={`card ${index === currentCard ? 'active' : ''}`}
							ref={index === currentCard ? activeCardRef : null}
							onClick={() => {
								goToCard(index);
							}}
						>
							{index === currentCard && <>
								<div className='card-title'>{card.title}</div>
								<div className='card-subtitle'>{card.subtitle}</div>
							</>}
							<span style={{position: 'relative', marginBottom: '2rem'}}>
								<img src={card.src} alt={card.alt}/>
								{index === currentCard && <div className='button-container'>
									<div className='button-action' onClick={() => {
										navigate('/consultants/' + card.id);
									}}>Read More</div>
								</div>}
							</span>
							{index === currentCard && <p>{card.text}</p>}
							{index === currentCard && <p className='subtext'>{card.subtext}</p>}
						</div>
					))}
				</div>}
			</div>
			<button className='prev-btn' onClick={goToPrevCard}>
        &#10094;
			</button>
			<button className='next-btn' onClick={goToNextCard}>
        &#10095;
			</button>
		</div>
	);
};

export default Carousel;
