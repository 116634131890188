import {documentToReactComponents} from '@contentful/rich-text-react-renderer';
import * as contentful from 'contentful';
import {fieldsParser} from 'contentful-parsers';
import {
	type ConsultantsSectionResponse,
	type WhoWeAreSectionResponse,
	type HeaderResponse,
	type OurOfficesResponse,
	type ConsultantsPageResponse,
	type ConsultantCardResponse,
	type HomeHeaderSectionResponse,
	type ContactResponse,
} from './types';
import {contentfulClient} from './ContentfulClient';

enum EntryIds {
	ConsultantsSection = '5MDFFXQmLl3g0RQITJgo7v',
	HomeHeaderSection = '6wFAfHoz6l79pi2D0UhIzz',
	WhoWeAreSection = '5p25oMTEksbKlKzR5UUtYF',
	Logo = '1YO6Vyi2Jnm3HPiN6FcOdw',
	OfficesSection = '3cTeV9dT3L1vuiW3gU0YIg',
	ConsultantsPage = '4aziODA5cQG9d7oeOucaf6',
	Contact = 'M7G39F1cuoOfMBc90zSvx',
}

function prepareConsultantsCards(cards: ConsultantCardResponse[]) {
	return cards.map(card => ({
		name: card.consultantName,
		skills: card.mainSkills,
		filterSkills: card.mainSkills,
		areaOfExpertise: documentToReactComponents(card.areasOfExpertiseAbstract),
		photoUrl: card?.consultantsPhoto?.file?.url,
		smallPhotoUrl: card?.consultantsPhoto?.file?.url,
		consultantFullName: card?.consultantsNameAndPosition,
		position: card.consultantsPosition,
		shortSummary: documentToReactComponents(card.areasOfExpertiseAbstract),
		summary: documentToReactComponents(card.areasOfExpertiseAbstract),
		id: card.id,
	}));
}

function prepareConsultantsCardsForHome(cards: ConsultantCardResponse[]) {
	return cards.map(card => ({
		name: card.consultantName,
		skills: card.consultantSkills,
		areaOfExpertise: documentToReactComponents(card.areasOfExpertiseAbstract),
		photoUrl: card?.consultantPhoto?.file?.url,
		position: card.consultantPosition,
		smallPhotoUrl: card?.consultantPhoto?.file?.url,
		summary: documentToReactComponents(card.consultantSummary),
		id: card.entryId,
		shortSummary: card.shortDescription,
	}));
}

export async function getConsultantsSection() {
	const entry = await contentfulClient.getEntry(EntryIds.ConsultantsSection);
	const {
		consultantsCards,
		consultantsSectionTitle,
		readMore,
	} = fieldsParser(entry) as ConsultantsSectionResponse;

	const cards = prepareConsultantsCardsForHome(consultantsCards);
	return {
		title: consultantsSectionTitle,
		cards,
		emptyCard: {
			title: readMore.emptyCardTitle,
			link: readMore.emptyCardLink,
			linkText: readMore.emptyCardLinkText,
			summary: documentToReactComponents(readMore.emptyCardSummary),
		},
	};
}

export async function getContactSection() {
	const entry = await contentfulClient.getEntry(EntryIds.Contact);

	const contact = fieldsParser(entry) as ContactResponse;
	return {
		...contact,
		image: contact?.image?.file?.url,
	};
}

export async function getHomeHeaderSection() {
	const entry = await contentfulClient.getEntry(EntryIds.HomeHeaderSection);
	const {image, title, subtitle} = fieldsParser(entry) as HomeHeaderSectionResponse;

	return {
		imageUrl: image?.file?.url,
		title,
		subtitle,
	};
}

export async function getWhoWeAreSection() {
	const entry = await contentfulClient.getEntry(EntryIds.WhoWeAreSection);
	const whoWeAreSection = fieldsParser(entry) as WhoWeAreSectionResponse;

	return {
		...whoWeAreSection,
		missionImage: whoWeAreSection?.missionImage?.file?.url,
		aboutShort: documentToReactComponents(whoWeAreSection.aboutUsShort),
		about: documentToReactComponents(whoWeAreSection.aboutUs),
		headerImage: whoWeAreSection?.headerImage?.file?.url,
		headerIcon: whoWeAreSection?.headerIcon?.file?.url,
	};
}

export async function getLogo() {
	const entry = await contentfulClient.getEntry(EntryIds.Logo);
	const {logo} = fieldsParser(entry) as HeaderResponse;

	return logo?.file?.url;
}

export async function getOffices() {
	const entry = await contentfulClient.getEntry(EntryIds.OfficesSection);

	const {ourOfficesTitle, ourOffices} = fieldsParser(entry) as OurOfficesResponse;

	return {
		ourOfficesTitle,
		ourOffices: ourOffices.map(({officePhoto, ...rest}) => ({...rest, photoUrl: officePhoto?.file?.url})),
	};
}

export async function getConsultantsPage() {
	const entry = await contentfulClient.getEntry(EntryIds.ConsultantsPage);
	const fields = fieldsParser(entry) as ConsultantsPageResponse;

	const {listOfConsultantsProfiles, consultantsPageTitle} = fields;
	return {
		consultantProfiles: listOfConsultantsProfiles,
		consultantCards: prepareConsultantsCards(listOfConsultantsProfiles),
		consultantsPageTitle,
	};
}
