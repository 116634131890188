import React from 'react';

import ConsultantsSection from '../sections/ConsultantsSection';
import WhoWeAreSection from '../sections/WhoWeAreSection';
import OurOfficesSection from '../sections/OurOfficesSection';
import HomeHeaderSection from '../sections/HomeHeaderSection';
import ContactSection from '../sections/ContactSection';

export default function Main() {
	return (
		<React.Fragment>
			<HomeHeaderSection />
			<WhoWeAreSection/>
			<ConsultantsSection/>
			<ContactSection />
		</React.Fragment>
	);
}
