import styled from '@emotion/styled';
import React from 'react';
import {SocialIcon} from 'react-social-icons';

const iconStyle = {
	border: 'solid 2px #FF746D',
	borderRadius: '50%',
	rotate: '15deg',
	cursor: 'pointer',
};

type IconProps = {
	url: string;
	network: string;
};

const FooterContainer = styled.div`
	width: 100%;
	position: absolute;
	bottom: -3rem;
`;

const DataContainer = styled.div`
	padding-right: 5rem;
	@media (max-width: 600px) {
		padding-right: 3rem;
	}
`;

const IconButton = ({url, network}: IconProps) => (
	<SocialIcon network={network} bgColor='transparent' fgColor='#FF746D' style={iconStyle} url={url} />
);
const contactText = `
Västmannagatan 15, 111 24 Stockholm
Fredrik Raynal, co-founder
+46 70 413 3392
fredrik@agilesearch.io
`;

export default function Footer() {
	return (
		<FooterContainer className='h-[600px] overflow-hidden mt-36'>
			<DataContainer className='bg-deep-purple text-white p-10 pr-20 -rotate-[15deg] h-[800px] mt-[250px] lg:mt-[300px] rounded-tr-[100px] mr-[5%]'>
				<div className='flex justify-end flex-wrap gap-8 w-full'>
					<IconButton network='instagram' url='https://www.instagram.com/agilesearch/' />
					<IconButton network='facebook' url='https://www.facebook.com/Agilesearchnews/' />
					<IconButton network='twitter' url='https://twitter.com/AgileSearchNews' />
					<IconButton network='linkedin' url='https://www.linkedin.com/company/agile-search/' />
					<IconButton network='youtube' url='https://www.youtube.com/channel/UCifnVjkHl2Bxq7QF4Tn5OBQ' />
				</div>
				<p className='text-end rotate-[15deg] whitespace-pre mt-8' id='contact-details'>{contactText}</p>
			</DataContainer>
		</FooterContainer>
	);
}
