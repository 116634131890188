import React from 'react';
import styled from '@emotion/styled';
import {type ParsedConsultantsPage} from '../../shared/types';

export type ConsultantsPreviewProps = ParsedConsultantsPage['consultantCards'][0];

const FullNameSection = styled.div`
	font-family: Platform Web;
	font-weight: 500;
	font-size: 2rem;
	color: var(--color-main-text);
`;

const PositionSection = styled.div`
	font-family: Platform Web;
	font-size: 1.6rem;
	color: var(--color-secondary-text);
`;

const SkillsSection = styled.div`
	font-family: Platform Web;
	font-size: 1.2rem;
	color: var(--color-secondary-text);
	margin-top: 1.5rem;
`;

export default function ConsultantPreview({smallPhotoUrl, position, name, skills, id}: ConsultantsPreviewProps) {
	return (
		<a href={`/consultants/${id}`} className='w-[310px] min-h-[500px] my-10 mx-[7%]'>
			<img
				src={smallPhotoUrl}
				alt={name}
				height={400}
				width={310}
				className='rounded-3xl mb-3'
				style={{height: '400px', objectFit: 'cover'}}
			/>
			<FullNameSection>{name}</FullNameSection>
			<PositionSection>{position}</PositionSection>
			<SkillsSection>{skills?.join(', ')}</SkillsSection>
		</a>
	);
}
