import React, {useEffect, useState} from 'react';
import {getHomeHeaderSection} from '../../shared/contentful';
import TriangleImage from '../../shared/ui/TriangleImage';
import styled from '@emotion/styled';
import BrightDownLeft from '../../assets/vectors/bright-down-left.svg';

const HomeHeader = styled.section`
	font-family: Platform Web;
	font-style: normal;
	display: flex;
	align-items: center;
	padding: 3rem;
	color: var(--color-main-text);
	@media (max-width: 900px) {
		justify-content: center;
		margin-bottom: 5rem;
	}
`;

const InfoDescription = styled.div`
	text-align: center;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	position: relative;
`;

const DescriptionTitle = styled.div`
	font-weight: 700;
	font-size: 62px;
	line-height: 53px;
	text-align: center;
	width: 400px;
	z-index: 1;
	background-color: var(--color-body);
`;

const DescriptionSubtitle = styled.div`
	width: 448px;
	max-width: 90vw;
	font-weight: 300;
	font-size: 32px;
	text-align: center;
	line-height: 37px;
	letter-spacing: -0.03em;
	margin-top: 0.5rem;
	z-index: 1;
	background-color: var(--color-body);
`;

const DescriptionDecoration = styled.img`
	position: absolute;
	z-index: 0;
	bottom: -100px;
    left: -10px;
`;

export default function HomeHeaderSection() {
	const [data, setData] = useState<Awaited<ReturnType<typeof getHomeHeaderSection>>>();

	useEffect(() => {
		(async () => {
			const resp = await getHomeHeaderSection();
			setData(resp);
		})();
	}, []);

	return <HomeHeader>
		<TriangleImage className='c-hidden' imageUrl={data?.imageUrl ?? ''}/>
		<InfoDescription>
			<DescriptionDecoration src={BrightDownLeft}></DescriptionDecoration>
			<DescriptionTitle>{data?.title}</DescriptionTitle>
			<DescriptionSubtitle>{data?.subtitle}</DescriptionSubtitle>
		</InfoDescription>
	</HomeHeader>;
}
