import React from 'react';
import {getWhoWeAreSection} from '../shared/contentful';
import styled from '@emotion/styled';
import MissionIcon from '../assets/icons/mission-icon.svg';
import SkillIcon from '../assets/icons/cup-icon.svg';
import ExpertiseIcon from '../assets/icons/expertise-icon.svg';
import TriangleImage from '../shared/ui/TriangleImage';

const PageContainer = styled.div`
	color: var(--color-main-text);
	font-family: Platform Web;
	> div {
		margin-bottom: 7rem;
	}
`;

const Header = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 2rem;
	max-width: 1200px;
	position: relative;
	height: 700px;
	padding: 2rem;
	padding-right: 32rem;
    font-weight: 500;
    font-size: 1.5rem;
    color: var(--color-main-text);
	margin-bottom: 5rem;
	@media (max-width: 900px) {
		text-align: center;
		padding-right: 2rem;
		> div {
			display: none;
		}
		height: auto;
	}
`;

const HeaderIcon = styled.img`
	height: 12rem;
`;

const SectionTitle = styled.div`
	position: relative;
	background-color: #1C7078;
	height: 4rem;
	color: #B1E0E4;
	font-weight: bold;
	width: 400px;
	@media (max-width: 600px) {
		max-width: calc(100% - 8rem);
	}
	justify-content: center;
	display: flex;
	align-items: center;
	font-size: 1.6rem;
`;

const MissionSection = styled.div`
	display: inline-block;
	color: var(--color-main-text);
	max-width: 100%;
	@media (max-width: 900px) {
		> div {
			flex-wrap: wrap;
			justify-content: center;
		}
	}
`;

const IconContainer = styled.div`
	border-radius: 50%;
	border: solid 1rem #1C7078;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Icon = styled.img`
	object-fit: cover;
	height: 2.1rem;
	width: 2.1rem;
`;

const MissionText = styled.div`
	color: var(--color-main-text);
	font-weight: 500;
	font-size: 1.5rem;
	padding-left: 2rem;
`;

const ExpertiseSection = styled.div`
	padding: 2rem;
	position: relative;
`;

const ExpertiseTitle = styled.div`
	position: absolute;
	top: 7rem;
	right: 0;
	color: #B1E0E4;
	z-index: 1;
`;

const ExpertiseList = styled.div`
	position: relative;
	background-color: #AEA8E9;
	border-radius: 70px;
	min-height: 600px;
	padding: 11rem 5rem 3rem;
	display: grid;
    justify-content: space-evenly;
    grid-template-columns: repeat(auto-fill, minmax(30%, 300px));
`;

const ListItemComponent = styled.div`
	display: flex;
	align-items: center;
	gap: 1.5rem;
	text-align: left;
`;

const ListItem = function ({text}: {text: string}) {
	return <ListItemComponent><img src={SkillIcon} />{text}</ListItemComponent>;
};

export default function AboutUs() {
	const [data, setData] = React.useState<Awaited<ReturnType<typeof getWhoWeAreSection>>>();

	React.useEffect(() => {
		(async () => {
			const resp = await getWhoWeAreSection();
			setData(resp);
		})();
	}, []);

	if (!data) {
		return null;
	}

	return (
		<PageContainer>
			<Header><HeaderIcon src={data.headerIcon}></HeaderIcon>{data.header}
				<TriangleImage className='about-us-header' imageUrl={data.headerImage ?? ''} />
			</Header>
			<MissionSection>
				<SectionTitle>{data.missionTitle}<IconContainer style={{position: 'absolute', right: '-7rem', padding: '1.8rem'}}>
					<Icon src={MissionIcon} />
				</IconContainer></SectionTitle>
				<div className='flex items-center p-8'>
					<img src={data.missionImage}></img>
					<MissionText>{data.mission}</MissionText>
				</div>
				<div className='px-16'>{data.vission}</div>
			</MissionSection>
			<ExpertiseSection>
				<ExpertiseTitle className='flex justify-end'>
					<SectionTitle>{data.areasOfExpertiseTitle}
						<IconContainer style={{position: 'absolute', left: '-7rem', padding: '1.8rem', paddingTop: '1.4rem', paddingBottom: '1.4rem', backgroundColor: '#B1E0E4'}}>
							<Icon style={{height: '2.7rem'}} src={ExpertiseIcon} />
						</IconContainer>
					</SectionTitle>
				</ExpertiseTitle>
				<ExpertiseList>
					{data.areasOfExpertise?.map(aoe => <ListItem key={aoe} text={aoe} />)}
				</ExpertiseList>
			</ExpertiseSection>
		</PageContainer>
	);
}
