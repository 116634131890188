import React, {useEffect, useState} from 'react';
import {getWhoWeAreSection} from '../../shared/contentful';
import Button from '../../shared/ui/Button';
import './index.css';
import {useNavigate} from 'react-router-dom';
import styled from '@emotion/styled';

const WhoWeAreContainer = styled.div`
	padding-left: 4rem;
	padding-right: 4rem;
	@media (max-width: 600px) {
		padding-left: 1rem;
		padding-right: 1rem;
	}
`;

export default function WhoWeAreSection() {
	const [data, setData] = useState<Awaited<ReturnType<typeof getWhoWeAreSection>>>();
	const navigate = useNavigate();

	useEffect(() => {
		(async () => {
			const resp = await getWhoWeAreSection();
			setData(resp);
		})();
	}, []);

	return (
		<WhoWeAreContainer className='p-12 m-10 mb-20 text-white bg-deep-purple rounded-3xl'>
			{data && (
				<div className='my-8 flex justify-center'>
					<div className='w-[800px] flex flex-1 gap-x-8 flex-col items-end'>
						<div className='pb-12 flex items-center gap-x-8 who-we-are-content'>
							{data.aboutShort}
						</div>
						<Button onClick={() => {
							console.log(data.readMoreLink);
							navigate(data.readMoreLink);
						}} text={data.readMoreText} />
					</div>
				</div>
			)}
		</WhoWeAreContainer>
	);
}
