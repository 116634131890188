import React from 'react';
import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';
import './TriangleImage.css';

type TriangleImageProps = {
	imageUrl: string;
	className?: string;
};

const StyledTriange = styled.div`
	${({imageUrl}: TriangleImageProps) => css`
		--b: url(${imageUrl}) center/cover
	`}
`;

export default function TriangleImage({imageUrl, className = ''}: TriangleImageProps) {
	return <>
		<StyledTriange className={`triangle-image ${className}`} imageUrl={imageUrl} />
		<svg style={{visibility: 'hidden', position: 'absolute'}} width='0' height='0' xmlns='http://www.w3.org/2000/svg' version='1.1'>
			<defs>
				<filter id='goo'><feGaussianBlur in='SourceGraphic' stdDeviation='8' result='blur' />
					<feColorMatrix in='blur' mode='matrix' values='1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9' result='goo' />
					<feComposite in='SourceGraphic' in2='goo' operator='atop'/>
				</filter>
			</defs>
		</svg>
	</>;
}
