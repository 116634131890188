import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {getConsultantProfile} from '../../entities/consultant/api/getConsultantEntry';
import {type ConsultantProfileResponse} from '../../entities/consultant/api/consultantProfileResponse';
import {
	Badge,
	Box,
	Button,
	Card,
	Flex,
	Heading,
	Image,
	Stack,
	Text,
} from '@chakra-ui/react';
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';
import styled from '@emotion/styled';
import InfoIcon from '../../assets/icons/info-icon.svg';
import EducationIcon from '../../assets/icons/education-icon.svg';
import ExperienceIcon from '../../assets/icons/experience-icon.svg';
import LanguagesIcon from '../../assets/icons/languages-icon.svg';
import SkillsIcon from '../../assets/icons/skills-icon.svg';

const MainSection = styled.div`
	display: flex;
	flex-direction: column;
	padding: 2rem;
	@media (max-width: 900px) {
		padding: 1rem;
	}
`;

const HeaderSection = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	text-align: start;
	@media (max-width: 900px) {
		justify-content: center;
	}
	text-align: start;
`;

const HeaderPhoto = styled.img`
	border-radius: 50%;
	object-fit: cover;
	height: 350px;
	width: 350px;
	margin-right: 2rem;
	@media (max-width: 900px) {
		margin-right: 0;
	}
	margin-bottom: 1rem;
`;

const HeaderTitle = styled.div`
	color: var(--color-main-alt-text);
	font-size: 3.5rem;
	line-height: 3.6rem;
	font-weight: 500;
	margin-bottom: 1rem;
	text-align: left;
`;

const HeaderSubtitle = styled.div`
	color: var(--color-main-text);
	font-size: 2rem;
`;

const CommonSection = styled.div`
	padding: 2rem 1rem;
`;

const CommonSectionTitle = styled.div`
	color: var(--color-main-text);
	font-size: 2.2rem;
	font-weight: 500;
	display: flex;
	align-items: center;
	text-align: left;
`;

const CommonSectionTitleIcon = styled.img`
	margin-right: 1rem;
`;

const CommonSectionContent = styled.div`
	color: var(--color-main-text);
	padding-left: 4.3rem;
	padding-top: 1rem;
    max-width: 1200px;

	li {
		list-style: disc;
	}

	ul {
		margin: 1rem 0 2rem;
	}
`;

export function ConsultantProfile() {
	const [consultantProfile, setConsultantProfile] = useState<ConsultantProfileResponse>();
	const {id} = useParams();

	if (!id) {
		return <></>;
	}

	useEffect(() => {
		(async () => {
			const {fields} = await getConsultantProfile(id);
			setConsultantProfile(fields);
		})();
	}, []);

	if (!consultantProfile) {
		return <></>;
	}

	const {
		consultantsNameAndPosition,
		consultantsPhoto,
		mainSkills,
		areasOfExpertiseAbstract,
		consultantsPosition,
		experience,
		languages,
		education,
	} = consultantProfile;
	return (
		<>
			<MainSection>
				<HeaderSection>
					<HeaderPhoto src={consultantsPhoto?.fields?.file?.url}></HeaderPhoto>
					<div>
						<HeaderTitle>{consultantsNameAndPosition}</HeaderTitle>
						<HeaderSubtitle>{consultantsPosition}</HeaderSubtitle>
					</div>
				</HeaderSection>
				{areasOfExpertiseAbstract && <CommonSection>
					<CommonSectionTitle><CommonSectionTitleIcon src={InfoIcon} />Summary</CommonSectionTitle>
					<CommonSectionContent>{documentToReactComponents(areasOfExpertiseAbstract)}</CommonSectionContent>
				</CommonSection>}
				{mainSkills && <CommonSection>
					<CommonSectionTitle><CommonSectionTitleIcon src={SkillsIcon} />Areas of Expertise - Skills</CommonSectionTitle>
					<CommonSectionContent>{mainSkills?.map(sk => <div key={sk}>{sk}</div>)}</CommonSectionContent>
				</CommonSection>}
				{education && <CommonSection>
					<CommonSectionTitle><CommonSectionTitleIcon src={EducationIcon} />Education</CommonSectionTitle>
				</CommonSection>}
				{languages && <CommonSection>
					<CommonSectionTitle><CommonSectionTitleIcon src={LanguagesIcon} />Languages</CommonSectionTitle>
					<CommonSectionContent>{languages?.map(lg => <div key={lg}>{lg}</div>)}</CommonSectionContent>
				</CommonSection>}
				{experience && <CommonSection>
					<CommonSectionTitle><CommonSectionTitleIcon src={ExperienceIcon} />Key Assignments or Experience</CommonSectionTitle>
					<CommonSectionContent>{documentToReactComponents(experience)}</CommonSectionContent>
				</CommonSection>}
			</MainSection>
		</>
	);
}
