import React, {useEffect, useState} from 'react';
import {getConsultantsPage} from '../../shared/contentful';
import {type ParsedConsultantsPage} from '../../shared/types';
import ConsultantPreview from './ConsultantPreview';
import SkillTag from './SkillTag';
import styled from '@emotion/styled';

const Title = styled.div`
	color: #1C7078;
	font-size: 4rem;
	font-weight: 500;
	margin-bottom: 2rem;
	line-height: 4rem;
	text-align: left;
	padding: 1rem;
`;

const ConsultantsContainer = styled.div`
	min-height: 25rem;
`;

function prepareFilterSkills(consultants?: ParsedConsultantsPage['consultantCards']) {
	if (!consultants) {
		return [];
	}

	return consultants.reduce<string[]>((acc, consultant) => {
		consultant.filterSkills.forEach(value => {
			const skill = value.toLowerCase();

			if (!acc.includes(skill)) {
				acc.push(skill);
			}
		});
		return acc;
	}, []);
}

export default function Consultants() {
	const [data, setData] = useState<ParsedConsultantsPage>();
	const [checkedSkills, setCheckedSkills] = useState<string[]>([]);
	const [showAll, setShowAll] = useState(true);

	useEffect(() => {
		(async () => {
			const resp = await getConsultantsPage();
			setData(resp);
		})();
	}, []);

	const toggleChecked = React.useCallback((value: string) => {
		setShowAll(false);
		if (checkedSkills.includes(value)) {
			const newCheckedSkills = checkedSkills.filter(v => v !== value).map(s => s.toLowerCase());
			setCheckedSkills(newCheckedSkills);

			if (newCheckedSkills.length === 0) {
				setShowAll(true);
			}
		} else {
			setCheckedSkills([...checkedSkills, value]);
		}
	}, [setCheckedSkills, setShowAll, checkedSkills]);

	const toggleShowAll = React.useCallback(() => {
		setShowAll(!showAll);
		setCheckedSkills([]);
	}, [showAll, setCheckedSkills, setShowAll]);

	const filteredConsultants = React.useMemo(() => {
		const consultants = data?.consultantCards ?? [];

		if (showAll) {
			return consultants;
		}

		return consultants.filter(({filterSkills}) => checkedSkills.every(skill => filterSkills.map(s => s.toLowerCase()).includes(skill.toLowerCase())));
	}, [data?.consultantCards, checkedSkills]);

	const skillsFilters = React.useMemo(() => prepareFilterSkills(data?.consultantCards), [data?.consultantCards]);

	if (!data) {
		return null;
	}

	return (
		<div className='mt-10 mb-6 text-deep-purple'>
			<Title>{data.consultantsPageTitle}</Title>
			{/*
				<div className='flex flex-wrap px-8'>
					<SkillTag
						value='show all'
						onClick={toggleShowAll}
						checked={showAll}
						className='mr-4 mt-4'
					/>
					{
						skillsFilters
							.map(skill => (
								<SkillTag
									value={skill.toLocaleLowerCase()}
									onClick={toggleChecked}
									key={skill}
									checked={checkedSkills.includes(skill)}
									className='mr-4 mt-4'
								/>
							))
					}
				</div>
				*/}
			<ConsultantsContainer className='pt-5 flex flex-wrap flex-row justify-center'>
				{filteredConsultants.map(consultant => <ConsultantPreview {...consultant} key={consultant.id}/>)}
			</ConsultantsContainer>
		</div>
	);
}
