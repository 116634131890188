import React, {useEffect, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';

import {type ConsultantContentParsed} from '../../shared/types';
import {getConsultantsSection} from '../../shared/contentful';

// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './index.css';
import Carousel from '../../shared/ui/Carousel';

export default function ConsultantsSection() {
	const [consultants, setConsultants] = React.useState<ConsultantContentParsed>();
	const [carouselCards, setCarouselCards] = useState([]);

	React.useEffect(() => {
		(async () => {
			const section = await getConsultantsSection();
			setConsultants(section);
		})();
	}, []);

	useEffect(() => {
		(() => {
			const cards = consultants?.cards?.map(card => ({
				id: card.id,
				title: card.name,
				alt: card.name,
				src: card.photoUrl,
				text: card.shortSummary,
				subtitle: card.position,
				subtext: card.skills?.join(' - '),
			}));
			setCarouselCards(cards as any);
		})();
	}, [consultants]);

	return (
		<section className='section-container'>
			<Carousel cards={carouselCards} carouselTitle='Meet Our Constultants'/>
		</section>
	);
}
