import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {ChakraProvider, extendTheme} from '@chakra-ui/react';

import Header from './shared/ui/Header';
import AboutUs from './pages/AboutUs';
import Main from './pages/Main';
import Consultants from './pages/Consultants';
import Footer from './shared/ui/Footer';
import {ConsultantProfile} from './pages/ConsultantProfile/ConsultantProfile';

import chakraTheme from '@chakra-ui/theme';

const theme = extendTheme({
	styles: {
		global: {
			'html, body': {
				backgroundColor: '#B1E0E4',
			},
		},
	},
});

export default function App() {
	return (
		<ChakraProvider theme={theme}>
			<BrowserRouter>
				<Header/>
				<div className='m-8' style={{maxWidth: 1200, margin: '0 auto', flex: 1}}>
					<Routes>
						<Route path='*' element={<Main/>}/>
						<Route path='/' element={<Main/>}/>
						<Route path='/about-us' element={<AboutUs/>}/>
						<Route path='/consultants/:id' element={<ConsultantProfile/>}/>
						<Route path='/consultants' element={<Consultants/>}/>
					</Routes>
				</div>
				<div style={{height: '30rem'}}></div>
				<Footer/>
			</BrowserRouter>
		</ChakraProvider>
	);
}
