import React, {useEffect, useState} from 'react';
import {getContactSection} from '../../shared/contentful';
import styled from '@emotion/styled';
import Button from '../../shared/ui/Button';

const Section = styled.section`
	display: flex;
	margin: 2.5rem;
	gap: 2.5rem;
	@media (max-width: 950px) {
		flex-wrap: wrap-reverse;
		justify-content: center;
	}
`;

const InfoSection = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	text-align: center;
	justify-content: center;
	gap: 2rem;
`;

const Image = styled.img`
	height: 560px;
	width: 675px;
	border-radius: 78px;
	object-fit: cover;
`;

const Description = styled.div`
	color: var(--color-main-text);
	font-size: 2.1rem;
    line-height: 2.2rem;
    text-align: center;
	font-weight: 500;
`;

const ActionText = styled.div`
	color: #1C7078;
	font-size: 4rem;
	font-weight: bold;
`;

export default function ContactSection() {
	const [data, setData] = useState<Awaited<ReturnType<typeof getContactSection>>>();

	const goToContacts = () => {
		window.scrollTo({top: document.body.scrollHeight, behavior: 'smooth'});
	};

	useEffect(() => {
		(async () => {
			const res = await getContactSection();
			setData(res);
		})();
	}, []);

	return (
		<Section>
			<InfoSection>
				<Description>{data?.description}</Description>
				<ActionText>{data?.actionText}</ActionText>
				{data && <Button style={{borderColor: '#1C7078', color: '#1C7078'}} onClick={goToContacts} text={data.buttonText}></Button>}
			</InfoSection>
			<Image src={data?.image}/>
		</Section>
	);
}
